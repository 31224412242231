import PropTypes from "prop-types";
import React from "react";

import ClubDetailStructuredData from "./ClubDetailStructuredData";
import MetaData from "./meta-data";

export default function SEOandAnalytics({ metaData, facilityData, clubData }) {
  return (
    <>
      {metaData && <MetaData metaData={metaData} />}

      {metaData && facilityData && clubData && (
        <ClubDetailStructuredData
          clubData={clubData}
          facilityData={facilityData}
          metaData={metaData}
        />
      )}
    </>
  );
}

SEOandAnalytics.propTypes = {
  clubData: PropTypes.object,
  facilityData: PropTypes.object,
  metaData: PropTypes.shape({
    allowSearchEngineIndex: PropTypes.bool,
    description: PropTypes.string,
    image: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    keywords: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};
