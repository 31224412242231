import { format as formatDate, parse as parseDate } from "date-fns";

const parseHours = (entry, outputFormat) => {
  let [openTimeString, closeTimeString] = entry?.split("-") || [];

  openTimeString = openTimeString.trim();
  closeTimeString = closeTimeString ? closeTimeString.trim() : null;

  try {
    return {
      close: formatDate(
        parseDate(closeTimeString, "hh:mm a", new Date()),
        outputFormat
      ).toLowerCase(),
      open: formatDate(
        parseDate(openTimeString, "hh:mm a", new Date()),
        outputFormat
      ).toLowerCase(),
    };
  } catch (e) {
    return {
      close: "Invalid date",
      open: "Invalid date",
    };
  }
};

module.exports = {
  convertFacilityApiLocationHours: (input, outputFormat = "HH:mm:00") => {
    if (!input?.length > 0) {
      return null;
    }

    const days = input;
    const schedule = [];
    const week = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

    days.forEach((entry) => {
      const isClosed = entry.hours.toLowerCase().search("closed") > -1;
      const splitHours = entry.hours.split(";");
      const openSessions = [];

      let [startDateString, endDateString] = entry.days.split("-");
      startDateString = startDateString.toLowerCase().trim();
      endDateString = endDateString ? endDateString.toLowerCase().trim() : null;
      endDateString = endDateString ? endDateString.toLowerCase().trim() : null;

      if (splitHours.length > 1) {
        splitHours.forEach((session) => {
          openSessions.push(parseHours(session, outputFormat));
        });
      } else {
        openSessions.push(parseHours(entry?.hours, outputFormat));
      }

      week.forEach((day) => {
        if (startDateString.toLowerCase().search(day) > -1) {
          const endDayIndex = week.indexOf(endDateString);
          schedule.push({
            hours: openSessions,
            index: week.indexOf(day),
            isClosed,
            isRange: endDayIndex,
            name: day,
          });
        }
      });
    });

    const clean = [];
    week.forEach((day, wIndex) => {
      const parentDay = schedule.filter((day) => {
        if (
          (day.isRange !== -1 &&
            wIndex >= day.index &&
            wIndex <= day.isRange) ||
          wIndex === day.index
        ) {
          return day;
        }
      });
      if (parentDay.length > 0) {
        clean[wIndex] = {
          hours: parentDay[0].hours,
          isClosed: parentDay[0].isClosed,
          name: week[wIndex],
        };
      }
    });

    return clean;
  },
};
