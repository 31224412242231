import Head from "next/head";
import PropTypes from "prop-types";
import React from "react";

const MetaData = ({ metaData }) => {
  const { allowSearchEngineIndex, description, image, keywords, title, url } =
    metaData;

  return (
    <Head>
      <title>{title}</title>
      {description && <meta content={description} name="description" />}
      {keywords && <meta content={keywords} name="keywords" />}
      {image && (
        <meta
          content={image && `https:${image.fields.file.url}`}
          property="og:image"
        />
      )}
      {url && <meta content={url} property="og:url" />}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content="@Equinox" name="twitter:site" />
      <meta
        content={allowSearchEngineIndex ? "index, follow" : "noindex, nofollow"}
        name="robots"
      />
      {url && <link href={url} rel="canonical" />}
    </Head>
  );
};

MetaData.propTypes = {
  metaData: PropTypes.shape({
    allowSearchEngineIndex: PropTypes.bool,
    description: PropTypes.string,
    image: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    keywords: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};

MetaData.defaultProps = {
  allowSearchEngineIndex: true,
  title: "Equinox",
};

export default MetaData;
